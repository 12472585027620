import call, { internalapiCall } from 'api/call';
import { runInAction } from 'mobx';

export const mobXStoreHelper = async (url, params = null, cb = null, method = "POST", errorCB) => {
    try {
        const response = await call(url, params, method);
        if (response.success) {
            runInAction(() => {
                cb(response)
            })
        } else {
            if(errorCB) {
                errorCB(response.message)
            }
        }
    } catch(error) {
        if(errorCB) {
            errorCB(error)
        }
    }
}

export const mobXStoreInternalApiHelper = async (method, url, params = null, cb) => {
    try {
        const response = await internalapiCall(method, url, params);

        runInAction(() => {
            cb(response)
        })
    } catch(error) {
        console.error(error)
    }
}
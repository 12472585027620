import { widgetCategoriesStaticData } from './constants';

export const widgetConstructor = (id, headline, category, type, holdingKey = null) => {
  const constructedWidget = {
    id,
    headline,
    category,
    type,
    // This is used for specifying the item in the holdings list, in case the data set contains all items from holdings
    holdingKey: holdingKey || null,
    ...widgetCategoriesStaticData[type]
  }
  return constructedWidget
}
import call from 'api/call';
import { APIS } from 'api/constants';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { dataSourceOptionsConstants } from "Pages/Assets/constants";
import { formattedDateToString, dateIntToDate, dateToInt, goYearsBack } from "utils/dateFormatters";
import { v4 as uuidv4 } from 'uuid';
import snackbar from "./snackbarStore";
import assetsOverview from "./assetsOverviewStore";
import data from "./dataStore";
import { getAssetInfo } from "utils/urlHelpers";
import { mobXStoreHelper } from 'utils/mobXStoreHelper';
import * as Sentry from "@sentry/react";

class AssetStore {
    assetHistoryDataFromDate = dateIntToDate(goYearsBack(data.calcDate));
    assetHistoryDataToDate = dateIntToDate(data.calcDate);
    disableHistory = false;
    selectedAssetHistoryKeys = [];
    selectedAsset = null;
    selectedAssetHistoryData = {};
    priceReturnTypeFilter = "Index";
    assetsHistoryChartInterval = 30;
    assetTable = [];
    selectedAssetBarChartData = [];

    constructor() {
        makeObservable(this, {
            assetHistoryDataFromDate: observable,
            assetHistoryDataToDate: observable,
            fetchAssetHistory: action.bound,
            setPriceReturnTypeFiler: action.bound,
            setSelectedAssetDataHistoryDate: action.bound,
            disableHistory: observable,
            selectedAssetHistoryKeys: observable,
            selectedAssetHistoryData: observable,
            selectedAsset: observable,
            priceReturnTypeFilter:observable,
            resetAssetMetaData: action.bound,
            fetchAsset: action.bound,
            assetsHistoryChartInterval: observable,
            assetTable: observable,
            selectedAssetBarChartData: observable,
        });
    }

    setPriceReturnTypeFiler(filterOption) {
        this.priceReturnTypeFilter = filterOption

        const assetsParams = this.selectedAssetHistoryKeys.map((ca) => ({ "AssetID": ca.split("-")[0], "CurrencyCode": ca.split("-")[1] }))
        this.fetchAssetHistory({ "Assets": assetsParams })
    }

    setSelectedAssetDataHistoryDate(date, from = true) {
        if (from) {
            this.assetHistoryDataFromDate = dateIntToDate(date)
        }
        else {
            this.assetHistoryDataToDate = dateIntToDate(date);
        }
        
        const assetsParams = this.selectedAssetHistoryKeys.map((ca) => ({ "AssetID": ca.split("-")[0], "CurrencyCode": ca.split("-")[1] }))
        this.fetchAssetHistory({ "Assets": assetsParams })
    }

    setAssetHistoryChartInterval(date) {
        let newInterval = 30
        
        const todayAsInt = dateToInt(dateIntToDate(data.calcDate));
        const yearsAgo = parseInt(todayAsInt / 10000) - parseInt(date / 10000);

        if(yearsAgo > 1) {
            newInterval = yearsAgo * 30
        }

        this.assetsHistoryChartInterval = newInterval
    }

    async fetchAssetHistory(payload) {
        this.disableHistory = false;

        try {
            const params = {...payload, FromDate: dateToInt(this.assetHistoryDataFromDate), ToDate: dateToInt(this.assetHistoryDataToDate), PriceReturnType: this.priceReturnTypeFilter }
            const response = await call(APIS.GET_ASSET_DATA, params);

            
            if (response.success) {
                runInAction(() => {
                    let charts = {}
                    response.message.Assets.forEach((element) => {
                        const key = element.AssetName
                        charts[key] = element.PriceData.map((item, i ) => {
                            return {
                                [key]: item.Price,
                                date: formattedDateToString(item.Dates),
                                [`${key} return`]: item.Return,
                                currency: element.CurrencyCode,
                                [`${key} Volume`]: element.VolumeData[i].Volume // + Math.floor(Math.random() * 100)
                            }
                        })
                    })

                    this.selectedAssetHistoryData = charts;
                    this.selectedAssetHistoryKeys = response.message.Assets.map((el) => (`${el.AssetID}-${el.CurrencyCode}`))
                    this.setAssetHistoryChartInterval(params.FromDate)
                })
            } else if(response.meta.data.ErrorCode === -57) {
                this.selectedAssetHistoryData = {};
                this.selectedAssetHistoryKeys = []
                this.disableHistory = true
                snackbar.handleOpen("You need to add prices to your asset, if you wish to see an asset history", "info")
            } else {
                runInAction(() => {
                    this.selectedAssetHistoryData = {};
                    this.selectedAssetHistoryKeys = []
                })
            }
        } catch(error) {
            runInAction(() => {
                snackbar.handleOpen("unable to fetch asset history", "error")
                // Sentry.captureException(new Error(error));
            })
        }
    }

    async resetAssetMetaData(assetUrl) {
        const { AssetID, CurrencyCode } = getAssetInfo(assetUrl);

        try {
            const response = await call(APIS.RESET_ASSET_META_DATA, { "AssetID": [AssetID], "CurrencyCode": [CurrencyCode] });

            if (response.success) {
                runInAction(() => {
                    this.fetchAsset(assetUrl, true)
                })
            } else {
                snackbar.handleOpen("unable to fetch asset", "error")
            }
        } catch(error) {
            snackbar.handleOpen("unable to fetch asset", "error")
            // Sentry.captureException(new Error(error));
        }
    }

    async fetchAsset(assetUrl, isReset = false, cb) {
        const { AssetID, CurrencyCode } = getAssetInfo(assetUrl);

        mobXStoreHelper(APIS.GET_ASSETS_STATIC_DATA, { AssetID, CurrencyCode }, (response) => {
            if(!response.message.AssetDB.length) {
                return;
            }
            
            this.selectedAsset = {...response.message.AssetDB[0].Data[0], id: uuidv4()}
            this.selectedAsset.Description = response.message.Description

            const foundAssetTypes = assetsOverview.assetTypes.filter((el) => el.name === response.message.AssetDB[0].AssetType)
            if(foundAssetTypes && foundAssetTypes.length) {
                assetsOverview.handleSetSelectedAssetType(foundAssetTypes[0])
            }

            if(this.selectedAsset.PriceSource && this.selectedAsset.PriceSource.toUpperCase() === dataSourceOptionsConstants.PROJECTION.toUpperCase()) {
                assetsOverview.updateAsset([this.selectedAsset])
            }
    
            const requestParams = { Assets: [{ AssetID, CurrencyCode }] }
            this.fetchAssetHistory(requestParams)
    
            this.assetTable = [this.selectedAsset]
        
            if(isReset) {
                snackbar.handleOpen("Asset has been successfully set to default", "success")
            }

            if(cb) {
                cb(this.selectedAsset)
            }
        })
    }
}

const assetStore = (window.assetsStore = new AssetStore());

export default assetStore;

import { action, makeObservable, observable } from 'mobx';

class NavigationStore {
  drawerSelectedElement = null;
  sidebar = {
    visible: false
  }

  constructor() {
      makeObservable(this, {
          drawerSelectedElement: observable,
          setDrawerSelectedElement: action.bound,
      });
  }

  setDrawerSelectedElement(value) {
      this.drawerSelectedElement = value;
  }
}

const navigationStore = (window.initialisationStore = new NavigationStore());

export default navigationStore;

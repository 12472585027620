import { APIS } from 'api/constants';
import { action, makeObservable, observable } from 'mobx';
import {mobXStoreHelper} from "utils/mobXStoreHelper" 
import customersStore from './Customer';
import shortid from 'shortid';

class RiskLimitBreachesStore {
    loading = false;
    breaches = [];
    totalBreachCount = 0;
    
    constructor() {
        makeObservable(this, {
            breaches: observable,
            totalBreachCount: observable,
            loading: observable,
            get: action.bound,
            setLoader: action.bound,
        });
    }

    setLoader = (value) => {
        this.loading = value;
    }

    async get(groupBy) {
        this.setLoader(true)

        // TODO: Find a better way to handle this
        if(!localStorage.getItem("EMPLOYEE_DATA")) {
            this.setLoader(false)
            return;
        }
        const UserID = JSON.parse(localStorage.getItem("USER")).id
        const params = { "UserID": UserID }
        mobXStoreHelper(APIS.GET_COMPLIANCE_BREACHES, params, (response) => {
            let totalBreachCount = 0
            const breaches = [];

            if(response.message.Breaches) {
                response.message.Breaches.forEach((el, index) => {         
                    if(el.BreachStatus !== 0) {
                        totalBreachCount += el.ID.length
                    }
                    if(el.Breach && el.Breach.length) {
                        el.Breach.forEach((breach, i) => {
                            let Customer = {};
                            let CustomerName = "";
    
                            if(customersStore.customersById[el.CustomerID]) {
                                Customer = customersStore.customersById[el.CustomerID];
                                CustomerName = `${Customer.name} (id: ${Customer.id})`;
                            }
    
                            let id;
                            let hierarchy = [];
    
                            if(groupBy === "Portfolio") { 
                                hierarchy.push(el.PortfolioName)
                            } else if(groupBy === "CustomerName") {
                                hierarchy.push(CustomerName)
                            }
    
                            if(!el.ID[i]) {
                                id = shortid.generate();
                            } else {
                                id = el.ID[i] + `__${i}${index}`;
                            }
                            
                            hierarchy = [...hierarchy, id]
    
                            breaches.push({
                                hierarchy,
                                CustomerName,
                                Customer,
                                PortfolioName: el.PortfolioName,
                                status: breach,
                                id,
                                AssetID: el.ID[i],
                                CurrentValue: el.CurrentValue[i],
                                MaxValue: el.MaxValue[i],
                                MinValue: el.MinValue[i],
                                Test_Type: el.Test_Type[i],
                                Tolerance: el.Tolerance[i], 
                                CurrencyCode: el.CurrencyCode[i],
                            });
                        })
                    }
                });
            }

            
            this.breaches = breaches;
            this.totalBreachCount = totalBreachCount;

            this.setLoader(false)
        })    
    }
}

const riskLimitBreachesStore = (window.riskLimitBreachesStore =
    new RiskLimitBreachesStore());

export default riskLimitBreachesStore;

import call from 'api/call';
import { APIS } from 'api/constants';
import { action, makeObservable, observable, runInAction } from 'mobx';
import data from "stores/dataStore";
import { exportToCSV } from "utils/externalFilesHelpers";
import { dateIntToDate, dateToInt, goYearsBack } from "utils/dateFormatters";
import snackbar from "stores/snackbarStore";
import assetStore from 'stores/assetStore';
import { getAssetInfo } from "utils/urlHelpers";
import {mobXStoreHelper} from "utils/mobXStoreHelper"
import { allCFTypeOptions } from "./constants";
import * as Sentry from "@sentry/react";

const constructCFRow = (AssetID, CurrencyCode, details) => {
    const rowToSave = { "AssetCurrencyCode": CurrencyCode, AssetID }

    if (details.CFAmount) {rowToSave.CFAmount = details.CFAmount}
    if (details.CFName) {rowToSave.CFName = details.CFName}
    if (details.CFType) {rowToSave.CFType = parseInt(details.CFType)}
    if (details.CurrencyCode) {rowToSave.CurrencyCode = details.CurrencyCode}
    if (details.FirstDate) {rowToSave.FirstDate = dateToInt(details.FirstDate)}
    if (details.LastDate) {rowToSave.LastDate = dateToInt(details.LastDate)}
    if (details.Frequency) {rowToSave.Frequency = parseInt(details.Frequency)}
    if (details.CFAmount) {rowToSave.CFAmount = parseInt(details.CFAmount)}
    if (details.RateOfIncrease) {rowToSave.RateOfIncrease = parseInt(details.RateOfIncrease)}
    if (details.Description) {rowToSave.Description = details.Description}

    return rowToSave;
}
class CashFlowStore {
    cashFlow = [];
    assetCashFlowDataFromDate = dateIntToDate(goYearsBack(data.calcDate, 10));
    isEditingCashflow = false;
    cfTypesLists = []

    constructor() {
        makeObservable(this, {
            assetCashFlowDataFromDate: observable,
            fetchCashFlow: action.bound,
            fetchCFTypesLists: action.bound,
            cfTypesLists: observable,
            updateCashFlow: action.bound,
            mergeCashFlow: action.bound,
            setSelectedAssetCashFlowDate: action.bound,
            cashFlow: observable,
            isEditingCashflow: observable,
            addRowToTopOfCashFlowTable: action.bound,
            exportTabContent: action.bound,
        });
    }

    setSelectedAssetCashFlowDate(date, assetId) {
        this.assetCashFlowDataFromDate = dateIntToDate(date)
        this.fetchCashFlow(assetId)
    }

    async fetchCFTypesLists() {

        mobXStoreHelper(APIS.GET_CF_TYPES_LISTS,{}, (response) => {
            runInAction(() => {
                
                if(assetStore.selectedAsset !== null) {
                    let allowedCFTypes = []

                    if(assetStore.selectedAsset.PriceSource === "VENDOR") {
                        allowedCFTypes = response.message.Vendor
                    } else {
                        if(assetStore.selectedAsset.ComplexType === "ALTERNATIVE") {
                            allowedCFTypes = response.message.Dependent.Alternative
                        } else if (assetStore.selectedAsset.ComplexType === "REAL_ESTATE") {
                            allowedCFTypes = response.message.Dependent.Real_Estate
                        } else {
                            allowedCFTypes = response.message.Dependent.Private_Equity
                        }
                    }

                    this.cfTypesLists = allCFTypeOptions.filter((el) => allowedCFTypes.includes(el.value))
                } else {
                    this.cfTypesLists = []
                }
            })
        })
    }

    addRowToTopOfCashFlowTable(row, cb) {
        this.cashFlow.unshift(row);
        if(cb) {
            cb();
        }
    }

    exportTabContent(assetUrl) {
        const fileName = `${assetUrl}_Cash-Flow.xlsx`;

        exportToCSV(this.cashFlow, fileName)
    }

    async mergeCashFlow(rows, assetUrl, cb) {
        const { AssetID, CurrencyCode } = getAssetInfo(assetUrl);
        const bulkUpload = {};
        const bulkUploadArray = [];

        // here we construct lists to match the api and the callback after
        rows.forEach((el) => {
            const newRow = constructCFRow(AssetID, CurrencyCode, el)
            bulkUpload[el.id] = newRow;
            bulkUploadArray.push(newRow);
        });
        mobXStoreHelper(APIS.UPDATE_CF_PROJECTIONS, bulkUpload, () => {
            cb(bulkUploadArray)
        })
    }

    async fetchCashFlow(assetUrl, cb) {
        const { AssetID, CurrencyCode } = getAssetInfo(assetUrl);
        
        mobXStoreHelper(APIS.GET_ASSET_CASH_FLOW, { AssetID, "AssetCurrencyCode": CurrencyCode, CalculationDate: dateToInt(this.assetCashFlowDataFromDate) }, (response) => {
            let firstCFDate = data.calcDate
            this.cashFlow = response.message.RawCF.map((el) => { 
                if(firstCFDate > el.FirstDate) {
                    firstCFDate = el.FirstDate
                }

                return {
                    ...el, id: el.CFID
                }
            })

            this.assetCashFlowDataFromDate = dateIntToDate(firstCFDate);

            if(cb) {
                cb(this.cashFlow);
            }
        })
    }

    async deleteCashFlowItem(row, apiRef) {
        try {
            const response = await call(APIS.DELETE_ASSET_CASH_FLOW_ITEM, { CFID: row.CFID });

            if(response.success) {
                const rowToDelete = apiRef.getRowIndex(row.id)
                const cfCopy = this.cashFlow;

                this.cashFlow = [...cfCopy.slice(0, rowToDelete), ...cfCopy.slice(rowToDelete + 1)]
                snackbar.handleOpen("Cash Flow item successfully deleted", "success")
            } else {
                const cfCopy = this.cashFlow;
                this.cashFlow = cfCopy.filter((x) => ![row.id].includes(x.id))
            }

        } catch(error) {
            snackbar.handleOpen("Unhandled error occured, please contact support", "error")
            // Sentry.captureException(new Error(error));
        }
    }

    async updateCashFlow(details, assetUrl, cb) {
        const { AssetID, CurrencyCode } = getAssetInfo(assetUrl);
        // when saving a row make sure to set id to a value from database
        try {

            const cfrow = constructCFRow(AssetID, CurrencyCode, details)

            const response = await call(APIS.UPDATE_CF_PROJECTIONS, { cfrow });
            
            // if success go away from edit mode
            if(response.success) {
                snackbar.handleOpen("Your cash flow has been successfully updated", "success")
                this.cashFlow.push(cfrow)
                if(cb) {
                    cb()
                }
            } else { // stick to edit mode
                snackbar.handleOpen("Unable to commit cash flow item, check for missing values", "error")
            }
        } catch(error) {
            snackbar.handleOpen("unable to update cashflow", "error")
            // Sentry.captureException(new Error(error));
        }
    }
}

const cashFlowStore = (window.cashFlowStore = new CashFlowStore());

export default cashFlowStore;
